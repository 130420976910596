.orderDisplay {
    width: 100%;
    background: lightgreen;
    margin-bottom: 1em;
    padding: .5em 1em;
    position: relative;
    border-radius: 5px;
    border: 2px solid lightgray;
    background: white;
}
.orderDisplay--client {
    margin-bottom: 10px;
}
.orderDisplay--delivery {
    margin-bottom: 10px;
}
.orderDisplay--client > span, .orderDisplay--delivery > span, .orderDisplay--products > span {
    font-weight: 500;
    display: block;
    width: 100%;
}
.haveAccount {
    position: absolute;
    right: 1em;
    top: .5em;
    color: seagreen;
}
.orderedProduct > p, .orderedProduct > b {
    display: block;
    width: 100%;
    padding: 0 20px;
}
.orderedProduct {
    margin-bottom: 10px;
}
.orderedProduct > b {
    font-weight: 500;
}
.orderDisplay > b {
    display: block;
}
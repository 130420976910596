@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap);
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Poppins', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.login__wrapper {
    width: 100%;
    height: 100vh;
    background-color: #125377;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='100%25' height='100%25' viewBox='0 0 1600 800'%3E%3Cg %3E%3Cpath fill='%231b618d' d='M486 705.8c-109.3-21.8-223.4-32.2-335.3-19.4C99.5 692.1 49 703 0 719.8V800h843.8c-115.9-33.2-230.8-68.1-347.6-92.2C492.8 707.1 489.4 706.5 486 705.8z'/%3E%3Cpath fill='%23256ea1' d='M1600 0H0v719.8c49-16.8 99.5-27.8 150.7-33.5c111.9-12.7 226-2.4 335.3 19.4c3.4 0.7 6.8 1.4 10.2 2c116.8 24 231.7 59 347.6 92.2H1600V0z'/%3E%3Cpath fill='%23317bb3' d='M478.4 581c3.2 0.8 6.4 1.7 9.5 2.5c196.2 52.5 388.7 133.5 593.5 176.6c174.2 36.6 349.5 29.2 518.6-10.2V0H0v574.9c52.3-17.6 106.5-27.7 161.1-30.9C268.4 537.4 375.7 554.2 478.4 581z'/%3E%3Cpath fill='%234286c2' d='M0 0v429.4c55.6-18.4 113.5-27.3 171.4-27.7c102.8-0.8 203.2 22.7 299.3 54.5c3 1 5.9 2 8.9 3c183.6 62 365.7 146.1 562.4 192.1c186.7 43.7 376.3 34.4 557.9-12.6V0H0z'/%3E%3Cpath fill='%235F92C3' d='M181.8 259.4c98.2 6 191.9 35.2 281.3 72.1c2.8 1.1 5.5 2.3 8.3 3.4c171 71.6 342.7 158.5 531.3 207.7c198.8 51.8 403.4 40.8 597.3-14.8V0H0v283.2C59 263.6 120.6 255.7 181.8 259.4z'/%3E%3Cpath fill='%235bb1d1' d='M1600 0H0v136.3c62.3-20.9 127.7-27.5 192.2-19.2c93.6 12.1 180.5 47.7 263.3 89.6c2.6 1.3 5.1 2.6 7.7 3.9c158.4 81.1 319.7 170.9 500.3 223.2c210.5 61 430.8 49 636.6-16.6V0z'/%3E%3Cpath fill='%2357d7de' d='M454.9 86.3C600.7 177 751.6 269.3 924.1 325c208.6 67.4 431.3 60.8 637.9-5.3c12.8-4.1 25.4-8.4 38.1-12.9V0H288.1c56 21.3 108.7 50.6 159.7 82C450.2 83.4 452.5 84.9 454.9 86.3z'/%3E%3Cpath fill='%2355ead2' d='M1600 0H498c118.1 85.8 243.5 164.5 386.8 216.2c191.8 69.2 400 74.7 595 21.1c40.8-11.2 81.1-25.2 120.3-41.7V0z'/%3E%3Cpath fill='%2354f5b7' d='M1397.5 154.8c47.2-10.6 93.6-25.3 138.6-43.8c21.7-8.9 43-18.8 63.9-29.5V0H643.4c62.9 41.7 129.7 78.2 202.1 107.4C1020.4 178.1 1214.2 196.1 1397.5 154.8z'/%3E%3Cpath fill='%2354FF98' d='M1315.3 72.4c75.3-12.6 148.9-37.1 216.8-72.4h-723C966.8 71 1144.7 101 1315.3 72.4z'/%3E%3C/g%3E%3C/svg%3E");
    background-attachment: fixed;
    background-size: cover;
    /* background by SVGBackgrounds.com */

    display: flex;
    justify-content: center;
    align-items: center;
}
.login__wrapper > form {
    width: 90%;
    max-width: 500px;
    padding: 1.5em 3em;
    background: rgba(0,0,0, 0.4);
    color: white;
    border-radius: 10px;
    box-shadow: 0 0px 50px rgb(0 0 0 / 40%);
}
.login__wrapper > form > h2 {
    margin-bottom: 1em;
}
.login__wrapper > form > label {
    font-size: 1.3rem;
    display: block;
    margin-bottom: .2em;
    font-weight: 500;
}
.login__wrapper > form > input {
    width: 100%;
    height: 45px;
    border: 2px solid white;
    margin-bottom: 1em;
    outline: 0;
    padding: 1em;
    transition: all .3s ease-in-out;
    font-weight: bold;
    font-size: 1.1rem;
    background: transparent;
    color: white;
}
.login__wrapper > form > input:focus {
    -webkit-transform: scale(1.05);
            transform: scale(1.05);
}
.login__wrapper > form > button {
    width: 100%;
    min-height: 45px;
    border: 2px solid white;
    background: transparent;
    color: white;
    font-weight: 700;
    font-size: 1.4rem;
    margin-top: 1em;
    cursor: pointer;
    transition: all .3s ease-in-out;
}
.login__wrapper > form > button:hover {
    background: white;
    color: #125377;
}
.dashboard__wrapper {
    width: 100%;
    height: 100vh;
    display: flex;
    overflow: hidden;
}
.dashboard__wrapper > nav {
    min-height: 100vh;
    width: 82px;
    background: white;
    padding: 2em 0;
    position: relative;
    box-shadow: 0 0px 10px rgb(0 0 0 / 20%); 
}
.dashboard__wrapper > nav > ul {
    width: 82px;  
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}
.dashboard__wrapper > nav > ul > a > li {
    width: 50px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 8px;
    transition: all .3s ease-in-out;
    cursor: pointer;
}
.dashboard__wrapper > nav > ul > a > li > svg {
    font-size: 30px;
    color: gray;
}
#activeIcon {
    box-shadow: 0 0px 10px rgb(0 0 0 / 20%);
    border-radius: 10px;
    transition: all .3s ease-in-out;
}
#activeIcon > svg {
    color: #3b5998;
    transition: all .3s ease-in-out;
}
#logoutIcon {
    color: red;
    font-size: 30px;
    position: absolute;
    bottom: 20px;
    left: 0;
    display: flex;
    justify-content: center;
    width: 100%;
}
#logoutIcon > svg {
    font-size: 30px;
    cursor: pointer;
}
.dashboard__wrapper > main {
    width: calc(100% - 82px);
    min-height: 100vh;
    background: #ededed;
    padding: 2em;
    overflow-x: hidden;
    overflow-y: auto;
}
.main__wrapper > h2 {
    width: 100%;
    margin-bottom: 1em;
    margin-top: 1em;
}
.main__wrapper > h2:first-child {
    margin-top: 0;
}
.statBox {
    width: 180px;
    padding: 1em;
    background: white;
    box-shadow: 0 0px 10px rgb(0 0 0 / 20%);
    border-radius: 16px;
}
.statBox:first-child {
    margin-left: 0;
}
.statBox > label {
    font-size: .8rem;
    text-align: center;
    display: block;
}
.statBox > h1 {
    text-align: center;
}

.swipeStatsBox {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: space-between;
}
@media(max-width: 521px) {
.swipeStatsBox {
    width: 180px;
}
.statBox {
    margin-bottom: 1em;
}
.statBox:last-child {
    margin-bottom: 0;
}
}
@media(min-width: 522px) and (max-width: 717px) {
    .swipeStatsBox {
        width: 376px;
    }
    .statBox {
        margin-bottom: 1em;
    }
    .statBox:last-child {
        margin-bottom: 0;
    }
}
@media(min-width: 718px) and (max-width: 913px) {
    .swipeStatsBox {
        width: 572px;
    }
    .statBox {
        margin-bottom: 1em;
    }
    .statBox:last-child {
        margin-bottom: 0;
    }
}
@media(min-width: 914px) {
    .swipeStatsBox {
        width: 768px;
    }
    .statBox {
        margin-bottom: 1em;
    }
    .statBox:last-child {
        margin-bottom: 0;
    }
}
.inbox {
    width: 100%;
    height: 346px;
    background: white;
    box-shadow: 0 0px 10px rgb(0 0 0 / 20%);
}
.messages__wrapper > h2 {
    margin-bottom: 1em;
}
.inbox--header {
    height: 50px;
    width: 100%;
    margin-bottom: 1em;
    display: flex;
    justify-content: space-between;
}
.inbox--header > span {
    height: 50px;
    display: flex;
    align-items: center;
    padding: 0 1.5em;
    font-size: 1rem;
    font-weight: 500;
    background: #3b5998;
    color: white;
    box-shadow: 0 0px 10px rgb(0 0 0 / 20%);
    cursor: pointer;
}
.inbox--header > span > svg {
    margin-right: .3em;
}
.inbox--header > ul {
    list-style: none;
    display: flex;
}
.inbox--header > ul > li {
    height: 50px;
    padding: 0 1.5em;
    display: flex;
    align-items: center;
    cursor: pointer;
    font-weight: 500;
    transition: all .3s ease-in-out;
}
#inboxActive {
    box-shadow: 0 0px 10px rgb(0 0 0 / 20%);
    color: #3b5998;
}

.inbox--messages {
    width: 100%;
    height: calc(100% - 50px - 1em);
    overflow-y: auto;
}

.message--item {
    width: 100%;
    height: 70px;
    padding: 10px;
    background: white;
    border-top: 2px solid #ededed;
    display: flex;
    cursor: pointer;
}
.message--item:last-child {
    border-bottom: 2px solid #ededed;
}

.blank--message {
    width: 100%;
    height: 280px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-content: center;
}
.blank--message > svg {
    height: 60px;
    width: 60px;
}
.blank--message > h2 {
    width: 100%;
    text-align: center;
}

.message--item--date {
    width: 100px;
    height: 100%;
    display: flex;
    font-size: .9rem;
    text-align: center;
    align-items: center;
}
.message--item--content {
    width: calc(100% - 100px);
    height: 100%;
    padding-right: 2em;
}
.message--item--content > b {
    font-size: .9rem;
}
.message--item--content > p {
    font-size: .8rem;
    overflow-y: hidden;
    height: 16px;
}

.inbox--details {
    width: 100%;
    background: white;
    box-shadow: 0 0px 10px rgb(0 0 0 / 20%);
    margin-top: 2em;
    padding: 1em;
    position: relative;
}
.inbox--details > span {
font-weight: 500;
font-size: 1.3rem;
}
.inbox--details > ul {
    list-style: none;
    margin-bottom: 1em;
}
.inbox--details > ul > li:nth-child(1) {
font-weight: 500;
}
.inbox--details > p {
margin-bottom: 2em;
}
.inbox--details > form {
    display: flex;
    align-items: flex-end;
}
.inbox--details > form > textarea {
    resize: none;
    width: calc(100% - 150px);
    height: 160px;
    padding: 10px;
    outline: 0;
    border: 2px solid lightgray;
}
.inbox--details > form > button {
    width: 130px;
    height: 40px;
    background: #3b5998;
    color: white;
    font-weight: 500;
    outline: 0;
    border: 0;
    margin-left: 20px;
    box-shadow: 0 0px 10px rgb(0 0 0 / 20%);
    cursor: pointer;
}
.deleteIcon {
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 1em;
    right: 1em;
    cursor: pointer;
}
.deleteIcon > svg {
    font-size: 30px;
    color: red;
}
.confirmDelete {
    width: 100%;
    height: 100%;
    background: rgba(0,0,0, .75);
    position: absolute;
    top: 0;
    left: 0;
    z-index: 999;
    color: white;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-content: center;
}
.confirmDelete > h2 {
    width: 100%;
    text-align: center;
    margin-bottom: 1em;
}
.confirmDelete > button {
    padding: .5em 1.5em;
    background: white;
    border: 2px solid #3b5998;
    font-weight: 500;
    font-size: 1rem;
    cursor: pointer;
}
.confirmDelete > button:nth-child(2) {
    margin-right: 2em;
}
.confirmDelete > button:nth-child(3) {
    background: red;
    color: white;
    border: 2px solid red;
}

.responseSended {
    display: flex;
    align-items: center;
    justify-content: center;
}
.responseSended > svg {
    font-size: 30px;
    margin-right: .5em;
    color: lightgreen;
}
.streetLoading {
    display: flex;
    justify-content: center;
    padding: 2em 0;
}
.filterStreet {
    padding: 1em 0;
}
.filterStreet > span {
    display: block;
    margin-bottom: 5px;
}
.filterStreet > label {
    width: 100%;
    display: flex;
    border: 2px solid #3b5998;
    overflow: hidden;
    border-radius: 5px;
    position: relative;
}
.deleteInputValue {
    position: absolute;
    right: 155px;
    top: 5px;
    color: red;
}
.deleteInputValue > svg {
    height: 35px;
    width: 35px;
}
.ctrForm {
    width: 100%;
    margin-top: 16px;
    display: flex;
}
.ctrForm--button {
    height: 45px;
    padding: 0 1.5em;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    background: #77ba9b	;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 16px;
    font-size: 1.1rem;
    font-weight: 500;
    border-radius: 5px;
    cursor: pointer;
}
.ctrForm--button > svg {
    margin-right: 5px;
}
.filterStreet > label > input {
    width: calc(100% - 150px);
    height: 45px;
    padding: 10px;
    font-size: 1.1rem;
    font-weight: 500;
    outline: 0;
    border: 0;
}
.filterStreet > label > button {
    width: 150px;
    height: 45px;
    outline: 0;
    border: 0;
    font-size: 1.1rem;
    font-weight: 500;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    background: #3b5998;
}
.filterStreet > label > button > svg {
    margin-right: 5px;
}
.listWrapper > span {
    display: block;
    margin-bottom: 5px;
}

.addNewStreet {
    width: 100%;
    margin-bottom: 10px;
}
.addNewStreet > form {
    display: flex;
    flex-direction: column;
}
.addNewStreet > form > label {
    display: block;
    margin-bottom: 5px;
}
.addNewStreet > form > input {
    height: 45px;
    margin-bottom: 10px;
    padding: 10px;
    outline: 0;
    border: 2px solid #77ba9b;
    border-radius: 5px;
    font-size: 1.1rem;
    font-weight: 500;
}
.addNewStreet > form > button {
    background-color: #77ba9b;
    height: 45px;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    padding: 0 2em;
    font-size: 1.1rem;
    font-weight: 500;
    border-radius: 5px;
    margin: 10px auto;
    cursor: pointer;
    border: 0;
    color: white;
}
.addedBox {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}
.addedBox > h2 {
    width: 100%;
    text-align: center;
}
.addedBox > button {
    background-color: #77ba9b;
    height: 45px;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    padding: 0 2em;
    font-size: 1.1rem;
    font-weight: 500;
    border-radius: 5px;
    margin: 10px auto;
    cursor: pointer;
    border: 0;
    color: white;
}
.streetItem {
    width: 100%;
    padding: 5px 0;
    display: flex;
    border-left: 1px solid;
    border-right: 1px solid;
    border-bottom: 1px solid;
}
.streetItem:nth-child(2) {
    border: 1px solid;
}
.streetItem--city {
    width: calc(20% - 10px);
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    border-right: 1px solid;
    font-size: .9rem;
    padding: 0 5px;
}
.streetItem--street {
    width: calc(20% - 10px);
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    border-right: 1px solid;
    font-size: .9rem;
    padding: 0 5px;
}
.streetItem--code {
    width: calc(20% - 10px);
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    border-right: 1px solid;
    font-size: .9rem;
    padding: 0 5px;
}
.streetItem--price {
    width: calc(20% - 25px);
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    border-right: 1px solid;
    font-size: .9rem;
    padding: 0 5px;
}
.streetItem--min {
    width: calc(20% - 25px);
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    border-right: 1px solid;
    font-size: .9rem;
    padding: 0 5px;
}
.streetItem--modify {
    width: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 5px;
}
.streetItem--delete {
    width: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 5px;
}
.streetItem--modify > svg {
    width: 20px;
    color: #3b5998;
    cursor: pointer;
}
.streetItem--delete > svg {
    width: 20px;
    color: red;
    cursor: pointer;
}

.confirmDeleteStreet {
    width: 100%;
    height: 100vh;
    background: rgba(0,0,0, .6);
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999;
    display: flex;
    justify-content: center;
    align-items: center;
}
.deleteBox {
    width: 90%;
    max-width: 500px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding: 1em 2em;
    background: white;
    border-radius: 5px;
}
.deleteBox > h2 {
    text-align: center;
    margin-bottom: 10px;
    color: #77ba9b;
    width: 100%;
}
.deleteBox > button {
    height: 45px;
    padding: 0 2em;
    font-weight: 500;
    font-size: 1.1rem;
    border: 0;
    outline: 0;
    border-radius: 5px;
    cursor: pointer;
}
.deleteBox > button:nth-child(2) {
    margin-right: 25px;
    background: grey;
    color: white;
}
.deleteBox > button:last-child {
    background: red;
    color: white;
}
#closePopBtn {
    margin: 0;
    background: grey;
}
.manageEarn {
    margin-top: 1em;
}
.manageEarn > span {
    display: block;
    margin-bottom: .5em;
}
.manageEarn > label {
    display: flex;
}
.manageEarn > label > button {
    display: flex;
    align-items: center;
    font-weight: 500;
    color: white;
    background: #3b5998;
    outline: 0;
    padding: 0 1.5em;
    margin-left: 1em;
    border: 1px solid transparent;
}
.manageEarn > label > div {
    flex-grow: 1;
}
.manageEarn > label > button > svg {
    margin-right: 5px;
    font-size: 20px;
}

.resultBox {
    width: 100%;
    padding: 1em 1.5em;
    background: #3b5998;
    color: white;
    margin-top: 2em;
    border-radius: 10px;
    box-shadow: 0 0px 10px rgb(0 0 0 / 20%);
    position: relative;
}
.resultBox > h1 {
    text-align:  center;
}
.resultBox > span {
    text-align: center;
    display: block;
}
.resultBox > button {
    position: absolute;
    top: 10px;
    right: 10px;
    padding: .5em 1.5em;
    border: 2px solid;
    background: white;
    border-radius: 5px;
    cursor: pointer;
    font-weight: 500;
}


.displayOrdersList {
    margin-top: 1em;
}
.loading {
    width: 100%;
    padding: 2em;
    display: flex;
    justify-content: center;
}
.listBlank {
    padding: 2em;
    text-align: center;
}
#listHead {
    margin-bottom: 10px;
}
.listWrapp {
    padding: 1em 0;
}
.clientListItem {
    width: 100%;
    margin-bottom: 1em;
    padding: .5em 1em;
    position: relative;
    border: 2px solid lightgray;
    border-radius: 5px;
    background: white;
}
.clientContact {
    margin-top: 5px;
}
.clientAdress {
    margin-top: 5px;
}
.clientAdress > span {
    display: flex;
    align-items: center;
}
.clientAdress > span > svg {
    margin-right: 10px;
    color: lightseagreen;
}
.verified {
position: absolute;
top: .5em;
right: 1em;
color: forestgreen;
}
.notverified {
    position: absolute;
top: .5em;
right: 1em;
color: red;
}
.edit {
    position: absolute;
    top: calc(.5em + 35px);
    right: 1em;
    color: orange;
    cursor: pointer;
}
.clientName > span {
    font-size: .9rem;
    color: gray;
}
.clientAdressEdit {
    display: flex;
    flex-direction: column;
}
.clientAdressEdit > input {
    height: 45px;
    width: 100%;
    border-radius: 5px;
    font-size: 1.1rem;
    font-weight: 500;
    border: 2px solid black;
    margin-top: 5px;
    margin-bottom: 10px;
    padding: 10px;
    outline: 0;
}
.clientAdressEdit > button {
    height: 45px;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    padding: 0 2em;
    margin-left: auto;
    margin-right: auto;
    margin-top: 10px;
    border: 0;
    outline: 0;
    background: lightgreen;
    font-weight: 500;
    font-size: 1.1rem;
    border-radius: 5px;
    color: white;
    cursor: pointer;
}
.orderDisplay {
    width: 100%;
    background: lightgreen;
    margin-bottom: 1em;
    padding: .5em 1em;
    position: relative;
    border-radius: 5px;
    border: 2px solid lightgray;
    background: white;
}
.orderDisplay--client {
    margin-bottom: 10px;
}
.orderDisplay--delivery {
    margin-bottom: 10px;
}
.orderDisplay--client > span, .orderDisplay--delivery > span, .orderDisplay--products > span {
    font-weight: 500;
    display: block;
    width: 100%;
}
.haveAccount {
    position: absolute;
    right: 1em;
    top: .5em;
    color: seagreen;
}
.orderedProduct > p, .orderedProduct > b {
    display: block;
    width: 100%;
    padding: 0 20px;
}
.orderedProduct {
    margin-bottom: 10px;
}
.orderedProduct > b {
    font-weight: 500;
}
.orderDisplay > b {
    display: block;
}

.inbox {
    width: 100%;
    height: 346px;
    background: white;
    box-shadow: 0 0px 10px rgb(0 0 0 / 20%);
}
.messages__wrapper > h2 {
    margin-bottom: 1em;
}
.inbox--header {
    height: 50px;
    width: 100%;
    margin-bottom: 1em;
    display: flex;
    justify-content: space-between;
}
.inbox--header > span {
    height: 50px;
    display: flex;
    align-items: center;
    padding: 0 1.5em;
    font-size: 1rem;
    font-weight: 500;
    background: #3b5998;
    color: white;
    box-shadow: 0 0px 10px rgb(0 0 0 / 20%);
    cursor: pointer;
}
.inbox--header > span > svg {
    margin-right: .3em;
}
.inbox--header > ul {
    list-style: none;
    display: flex;
}
.inbox--header > ul > li {
    height: 50px;
    padding: 0 1.5em;
    display: flex;
    align-items: center;
    cursor: pointer;
    font-weight: 500;
    transition: all .3s ease-in-out;
}
#inboxActive {
    box-shadow: 0 0px 10px rgb(0 0 0 / 20%);
    color: #3b5998;
}

.inbox--messages {
    width: 100%;
    height: calc(100% - 50px - 1em);
    overflow-y: auto;
}

.message--item {
    width: 100%;
    height: 70px;
    padding: 10px;
    background: white;
    border-top: 2px solid #ededed;
    display: flex;
    cursor: pointer;
}
.message--item:last-child {
    border-bottom: 2px solid #ededed;
}

.blank--message {
    width: 100%;
    height: 280px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-content: center;
}
.blank--message > svg {
    height: 60px;
    width: 60px;
}
.blank--message > h2 {
    width: 100%;
    text-align: center;
}

.message--item--date {
    width: 100px;
    height: 100%;
    display: flex;
    font-size: .9rem;
    text-align: center;
    align-items: center;
}
.message--item--content {
    width: calc(100% - 100px);
    height: 100%;
    padding-right: 2em;
}
.message--item--content > b {
    font-size: .9rem;
}
.message--item--content > p {
    font-size: .8rem;
    overflow-y: hidden;
    height: 16px;
}

.inbox--details {
    width: 100%;
    background: white;
    box-shadow: 0 0px 10px rgb(0 0 0 / 20%);
    margin-top: 2em;
    padding: 1em;
    position: relative;
}
.inbox--details > span {
font-weight: 500;
font-size: 1.3rem;
}
.inbox--details > ul {
    list-style: none;
    margin-bottom: 1em;
}
.inbox--details > ul > li:nth-child(1) {
font-weight: 500;
}
.inbox--details > p {
margin-bottom: 2em;
}
.inbox--details > form {
    display: flex;
    align-items: flex-end;
}
.inbox--details > form > textarea {
    resize: none;
    width: calc(100% - 150px);
    height: 160px;
    padding: 10px;
    outline: 0;
    border: 2px solid lightgray;
}
.inbox--details > form > button {
    width: 130px;
    height: 40px;
    background: #3b5998;
    color: white;
    font-weight: 500;
    outline: 0;
    border: 0;
    margin-left: 20px;
    box-shadow: 0 0px 10px rgb(0 0 0 / 20%);
    cursor: pointer;
}
.deleteIcon {
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 1em;
    right: 1em;
    cursor: pointer;
}
.deleteIcon > svg {
    font-size: 30px;
    color: red;
}
.confirmDelete {
    width: 100%;
    height: 100%;
    background: rgba(0,0,0, .75);
    position: absolute;
    top: 0;
    left: 0;
    z-index: 999;
    color: white;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-content: center;
}
.confirmDelete > h2 {
    width: 100%;
    text-align: center;
    margin-bottom: 1em;
}
.confirmDelete > button {
    padding: .5em 1.5em;
    background: white;
    border: 2px solid #3b5998;
    font-weight: 500;
    font-size: 1rem;
    cursor: pointer;
}
.confirmDelete > button:nth-child(2) {
    margin-right: 2em;
}
.confirmDelete > button:nth-child(3) {
    background: red;
    color: white;
    border: 2px solid red;
}

.responseSended {
    display: flex;
    align-items: center;
    justify-content: center;
}
.responseSended > svg {
    font-size: 30px;
    margin-right: .5em;
    color: lightgreen;
}
.dashboard__wrapper {
    width: 100%;
    height: 100vh;
    display: flex;
    overflow: hidden;
}
.dashboard__wrapper > nav {
    min-height: 100vh;
    width: 82px;
    background: white;
    padding: 2em 0;
    position: relative;
    box-shadow: 0 0px 10px rgb(0 0 0 / 20%); 
}
.dashboard__wrapper > nav > ul {
    width: 82px;  
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}
.dashboard__wrapper > nav > ul > a > li {
    width: 50px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 8px;
    transition: all .3s ease-in-out;
    cursor: pointer;
}
.dashboard__wrapper > nav > ul > a > li > svg {
    font-size: 30px;
    color: gray;
}
#activeIcon {
    box-shadow: 0 0px 10px rgb(0 0 0 / 20%);
    border-radius: 10px;
    transition: all .3s ease-in-out;
}
#activeIcon > svg {
    color: #3b5998;
    transition: all .3s ease-in-out;
}
#logoutIcon {
    color: red;
    font-size: 30px;
    position: absolute;
    bottom: 20px;
    left: 0;
    display: flex;
    justify-content: center;
    width: 100%;
}
#logoutIcon > svg {
    font-size: 30px;
    cursor: pointer;
}
.dashboard__wrapper > main {
    width: calc(100% - 82px);
    min-height: 100vh;
    background: #ededed;
    padding: 2em;
    overflow-x: hidden;
    overflow-y: auto;
}
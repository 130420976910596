.loading {
    width: 100%;
    padding: 2em;
    display: flex;
    justify-content: center;
}
.listBlank {
    padding: 2em;
    text-align: center;
}
#listHead {
    margin-bottom: 10px;
}
.listWrapp {
    padding: 1em 0;
}
.filterStreet {
    padding: 1em 0;
}
.filterStreet > span {
    display: block;
    margin-bottom: 5px;
}
.filterStreet > label {
    width: 100%;
    display: flex;
    border: 2px solid #3b5998;
    overflow: hidden;
    border-radius: 5px;
    position: relative;
}
.deleteInputValue {
    position: absolute;
    right: 155px;
    top: 5px;
    color: red;
}
.deleteInputValue > svg {
    height: 35px;
    width: 35px;
}
.ctrForm {
    width: 100%;
    margin-top: 16px;
    display: flex;
}
.ctrForm--button {
    height: 45px;
    padding: 0 1.5em;
    width: fit-content;
    background: #77ba9b	;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 16px;
    font-size: 1.1rem;
    font-weight: 500;
    border-radius: 5px;
    cursor: pointer;
}
.ctrForm--button > svg {
    margin-right: 5px;
}
.filterStreet > label > input {
    width: calc(100% - 150px);
    height: 45px;
    padding: 10px;
    font-size: 1.1rem;
    font-weight: 500;
    outline: 0;
    border: 0;
}
.filterStreet > label > button {
    width: 150px;
    height: 45px;
    outline: 0;
    border: 0;
    font-size: 1.1rem;
    font-weight: 500;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    background: #3b5998;
}
.filterStreet > label > button > svg {
    margin-right: 5px;
}
.listWrapper > span {
    display: block;
    margin-bottom: 5px;
}

.addNewStreet {
    width: 100%;
    margin-bottom: 10px;
}
.addNewStreet > form {
    display: flex;
    flex-direction: column;
}
.addNewStreet > form > label {
    display: block;
    margin-bottom: 5px;
}
.addNewStreet > form > input {
    height: 45px;
    margin-bottom: 10px;
    padding: 10px;
    outline: 0;
    border: 2px solid #77ba9b;
    border-radius: 5px;
    font-size: 1.1rem;
    font-weight: 500;
}
.addNewStreet > form > button {
    background-color: #77ba9b;
    height: 45px;
    width: fit-content;
    padding: 0 2em;
    font-size: 1.1rem;
    font-weight: 500;
    border-radius: 5px;
    margin: 10px auto;
    cursor: pointer;
    border: 0;
    color: white;
}
.addedBox {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}
.addedBox > h2 {
    width: 100%;
    text-align: center;
}
.addedBox > button {
    background-color: #77ba9b;
    height: 45px;
    width: fit-content;
    padding: 0 2em;
    font-size: 1.1rem;
    font-weight: 500;
    border-radius: 5px;
    margin: 10px auto;
    cursor: pointer;
    border: 0;
    color: white;
}
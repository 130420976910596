.clientListItem {
    width: 100%;
    margin-bottom: 1em;
    padding: .5em 1em;
    position: relative;
    border: 2px solid lightgray;
    border-radius: 5px;
    background: white;
}
.clientContact {
    margin-top: 5px;
}
.clientAdress {
    margin-top: 5px;
}
.clientAdress > span {
    display: flex;
    align-items: center;
}
.clientAdress > span > svg {
    margin-right: 10px;
    color: lightseagreen;
}
.verified {
position: absolute;
top: .5em;
right: 1em;
color: forestgreen;
}
.notverified {
    position: absolute;
top: .5em;
right: 1em;
color: red;
}
.edit {
    position: absolute;
    top: calc(.5em + 35px);
    right: 1em;
    color: orange;
    cursor: pointer;
}
.clientName > span {
    font-size: .9rem;
    color: gray;
}
.clientAdressEdit {
    display: flex;
    flex-direction: column;
}
.clientAdressEdit > input {
    height: 45px;
    width: 100%;
    border-radius: 5px;
    font-size: 1.1rem;
    font-weight: 500;
    border: 2px solid black;
    margin-top: 5px;
    margin-bottom: 10px;
    padding: 10px;
    outline: 0;
}
.clientAdressEdit > button {
    height: 45px;
    width: fit-content;
    padding: 0 2em;
    margin-left: auto;
    margin-right: auto;
    margin-top: 10px;
    border: 0;
    outline: 0;
    background: lightgreen;
    font-weight: 500;
    font-size: 1.1rem;
    border-radius: 5px;
    color: white;
    cursor: pointer;
}
.streetItem {
    width: 100%;
    padding: 5px 0;
    display: flex;
    border-left: 1px solid;
    border-right: 1px solid;
    border-bottom: 1px solid;
}
.streetItem:nth-child(2) {
    border: 1px solid;
}
.streetItem--city {
    width: calc(20% - 10px);
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    border-right: 1px solid;
    font-size: .9rem;
    padding: 0 5px;
}
.streetItem--street {
    width: calc(20% - 10px);
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    border-right: 1px solid;
    font-size: .9rem;
    padding: 0 5px;
}
.streetItem--code {
    width: calc(20% - 10px);
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    border-right: 1px solid;
    font-size: .9rem;
    padding: 0 5px;
}
.streetItem--price {
    width: calc(20% - 25px);
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    border-right: 1px solid;
    font-size: .9rem;
    padding: 0 5px;
}
.streetItem--min {
    width: calc(20% - 25px);
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    border-right: 1px solid;
    font-size: .9rem;
    padding: 0 5px;
}
.streetItem--modify {
    width: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 5px;
}
.streetItem--delete {
    width: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 5px;
}
.streetItem--modify > svg {
    width: 20px;
    color: #3b5998;
    cursor: pointer;
}
.streetItem--delete > svg {
    width: 20px;
    color: red;
    cursor: pointer;
}

.confirmDeleteStreet {
    width: 100%;
    height: 100vh;
    background: rgba(0,0,0, .6);
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999;
    display: flex;
    justify-content: center;
    align-items: center;
}
.deleteBox {
    width: 90%;
    max-width: 500px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding: 1em 2em;
    background: white;
    border-radius: 5px;
}
.deleteBox > h2 {
    text-align: center;
    margin-bottom: 10px;
    color: #77ba9b;
    width: 100%;
}
.deleteBox > button {
    height: 45px;
    padding: 0 2em;
    font-weight: 500;
    font-size: 1.1rem;
    border: 0;
    outline: 0;
    border-radius: 5px;
    cursor: pointer;
}
.deleteBox > button:nth-child(2) {
    margin-right: 25px;
    background: grey;
    color: white;
}
.deleteBox > button:last-child {
    background: red;
    color: white;
}
#closePopBtn {
    margin: 0;
    background: grey;
}
.manageEarn {
    margin-top: 1em;
}
.manageEarn > span {
    display: block;
    margin-bottom: .5em;
}
.manageEarn > label {
    display: flex;
}
.manageEarn > label > button {
    display: flex;
    align-items: center;
    font-weight: 500;
    color: white;
    background: #3b5998;
    outline: 0;
    padding: 0 1.5em;
    margin-left: 1em;
    border: 1px solid transparent;
}
.manageEarn > label > div {
    flex-grow: 1;
}
.manageEarn > label > button > svg {
    margin-right: 5px;
    font-size: 20px;
}

.resultBox {
    width: 100%;
    padding: 1em 1.5em;
    background: #3b5998;
    color: white;
    margin-top: 2em;
    border-radius: 10px;
    box-shadow: 0 0px 10px rgb(0 0 0 / 20%);
    position: relative;
}
.resultBox > h1 {
    text-align:  center;
}
.resultBox > span {
    text-align: center;
    display: block;
}
.resultBox > button {
    position: absolute;
    top: 10px;
    right: 10px;
    padding: .5em 1.5em;
    border: 2px solid;
    background: white;
    border-radius: 5px;
    cursor: pointer;
    font-weight: 500;
}


.displayOrdersList {
    margin-top: 1em;
}
.main__wrapper > h2 {
    width: 100%;
    margin-bottom: 1em;
    margin-top: 1em;
}
.main__wrapper > h2:first-child {
    margin-top: 0;
}
.statBox {
    width: 180px;
    padding: 1em;
    background: white;
    box-shadow: 0 0px 10px rgb(0 0 0 / 20%);
    border-radius: 16px;
}
.statBox:first-child {
    margin-left: 0;
}
.statBox > label {
    font-size: .8rem;
    text-align: center;
    display: block;
}
.statBox > h1 {
    text-align: center;
}

.swipeStatsBox {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: space-between;
}
@media(max-width: 521px) {
.swipeStatsBox {
    width: 180px;
}
.statBox {
    margin-bottom: 1em;
}
.statBox:last-child {
    margin-bottom: 0;
}
}
@media(min-width: 522px) and (max-width: 717px) {
    .swipeStatsBox {
        width: 376px;
    }
    .statBox {
        margin-bottom: 1em;
    }
    .statBox:last-child {
        margin-bottom: 0;
    }
}
@media(min-width: 718px) and (max-width: 913px) {
    .swipeStatsBox {
        width: 572px;
    }
    .statBox {
        margin-bottom: 1em;
    }
    .statBox:last-child {
        margin-bottom: 0;
    }
}
@media(min-width: 914px) {
    .swipeStatsBox {
        width: 768px;
    }
    .statBox {
        margin-bottom: 1em;
    }
    .statBox:last-child {
        margin-bottom: 0;
    }
}